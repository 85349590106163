/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {Helmet} from "react-helmet"
import {graphql, useStaticQuery} from "gatsby"

function SEO({description, lang, meta, title, image: metaImage, pathname}) {
    const {site, file} = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        url
                    }
                }
                file(relativePath: {eq: "bg-social.jpeg"}) {
                    id
                    childImageSharp {
                        resize(width: 1200) {
                            src
                            height
                            width
                        }
                    }
                }
            }
        `
    )

    const metaDescription = description || site.siteMetadata.description
    const image =
        metaImage && metaImage.src
            ? `${site.siteMetadata.siteUrl}${metaImage.src}`
            : `${site.siteMetadata.url}${file.childImageSharp.resize.src}`;

    const canonical = pathname ? `${site.siteMetadata.url}${pathname}` : null

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            link={
                canonical
                    ? [
                        {
                            rel: "canonical",
                            href: canonical,
                        },
                    ]
                    : []
            }
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:image`,
                    content: image,
                },
                {
                    property: `og:url`,
                    content: site.siteMetadata.url,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ].concat(
                metaImage
                    ? [
                        {
                            property: "og:image",
                            content: image,
                        },
                        {
                            property: "og:image:width",
                            content: metaImage.width,
                        },
                        {
                            property: "og:image:height",
                            content: metaImage.height,
                        },
                        {
                            name: "twitter:card",
                            content: "summary_large_image",
                        },
                    ]
                    : [
                        {
                            name: "twitter:card",
                            content: "summary",
                        },
                    ]
            )
                .concat(meta)}
        />
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
    image: null,
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    image: PropTypes.shape({
        src: PropTypes.string.isRequired,
        height: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
    }),
    pathname: PropTypes.string,

};

export default SEO
