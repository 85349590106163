import React from "react"
import PropTypes from "prop-types"
import Link from "gatsby-link"

import {
  Button,
  ButtonGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap"

const SelectLanguage = props => {
  return (
    <div className={props.className}>
      <UncontrolledDropdown>
        <DropdownToggle
          aria-expanded={false}
          aria-haspopup={true}
          caret
          color="transparent"
          data-toggle="dropdown"
          id="dropdownMenuButton"
          type="button"
        >
          {props.lang}
        </DropdownToggle>
        <DropdownMenu aria-labelledby="dropdownMenuButton">
          {props.lang != "PL" && <DropdownItem href="/">PL</DropdownItem>}
          {props.lang != "EN" && <DropdownItem href="/en">EN</DropdownItem>}
          {props.lang != "DE" && <DropdownItem href="/de">DE</DropdownItem>}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  )
}

SelectLanguage.propTypes = {
  langs: PropTypes.array,
}

export default SelectLanguage
